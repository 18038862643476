<template>
    <div>
        <orders-history-card :calledFrom="'orders'"></orders-history-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import OrdersHistoryCard from './OrdersHistoryCard.vue';

export default Vue.extend({

    created() {
        this.$store.dispatch( 'updatePageTitle', {
            title: this.$t('Pedidos'),
            subtitle: this.$t('Historial de Pedidos'),
        });
    },

    components: {
        OrdersHistoryCard,
    }
})
</script>
<style lang="scss">
.card_total1{
  margin-top:30px;
  background-color:white;
  height:auto;
  border: 0.2px black;
  border-radius: 15px !important;
  box-shadow: 1px 3px 3px 3px rgba(46, 52, 57, 0.164);
  padding-bottom: 15px;
}

.v-line1{
  //border-left: thick solid #000;
  border-left: solid #000;
  height:100%;
  padding-left: 20px;
  padding-right: 20px;
}
</style>